import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";

import { getAnnouncementAPI, getUserDataAPI } from "../../service/AuthService";

function Header({ isSidebarOpen, setIsSidebarOpen }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobileUI, setIsMobileUI] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobileUI(window.innerWidth < 768);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("sidebar-mini");
    return () => {
      document.body.classList.remove("sidebar-open");
    };
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
    // document.body.classList.toggle('sidebar-collapse');
  };

  const handleMenuToggleMobile = () => {
    setMenuOpen(!isMenuOpen);
    if (isMobileUI) {
      document.body.classList.toggle("sidebar-open");
    }
  };

  const location = useLocation();
  const active = location.pathname.split("/")[1];
  const pathname = location.pathname;
  const isLedger = pathname.startsWith("/ledger");
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const _isMounted = useRef(true);
  const [announcement, setAnnouncement] = React.useState({});
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const openMobileDrawer = () => {
    dispatch({
      type: "accountData/setIsSideBarOpen",
      payload: userData.isSideBarOpen ? false : true,
    });
  };
  const closeMobileDrawer = () => {
    dispatch({
      type: "accountData/setIsSideBarOpen",
      payload: false,
    });
  };
  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);
  const getUserData = async () => {
    var { response, code } = await getUserDataAPI(userData.id);
    if (code === 200) {
      dispatch({
        type: "accountData/setUserProfile",
        payload: response,
      });
    } else {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
    }
  };

  const getAnnouncement = async () => {
    const { response } = await getAnnouncementAPI();
    setAnnouncement(response);
  };

  const fetchProfile = () => {
    const interval = setInterval(getUserData, 5000);
    return () => clearInterval(interval);
  };
  const logOut = () => {
    dispatch({
      type: "accountData/logOut",
      payload: {},
    });
    dispatch({
      type: "gameData/logOut",
      payload: {},
    });
  };

  useEffect(() => {
    getUserData();
    if (_isMounted.current) {
      fetchProfile();
      getAnnouncement();
    }
  }, []);
  useEffect(() => {
    setIsSidebarOpen(false);
  }, [location.pathname]);

  if (!userData.token) {
    return (window.location.href = "/");
  }


  return (
    <div _ngcontent-ng-c3705319800="" className="dash_header">
      <div
        _ngcontent-ng-c3705319800=""
        className="header_user_balance d-lg-none d-block"
      >
        <div _ngcontent-ng-c3705319800="" className="user_right_bal">
          <span _ngcontent-ng-c3705319800="" className="user_name">
            <span _ngcontent-ng-c3705319800="" />
          </span>
          <span
            style={{
              color: "white",
              width: "100%",
            }}
            _ngcontent-ng-c3705319800=""
            className="User_balance"
          >
            <marquee>{announcement?.announcement?.toUpperCase()}</marquee>
          </span>
        </div>
      </div>
      <app-header _ngcontent-ng-c3705319800="" _nghost-ng-c453458495="">
        <div _ngcontent-ng-c453458495="" className="dash_logo">
          <a _ngcontent-ng-c453458495="" href="/">
            <img
              _ngcontent-ng-c453458495=""
              alt=""
              tabIndex={0}
              src="logo.png"
            />
            <svg _ngcontent-ng-c453458495="" className="logo_fold">
              <use _ngcontent-ng-c453458495="" xlinkHref="#football" />
            </svg>
          </a>
        </div>
        <div _ngcontent-ng-c453458495="" className="nav_dash_wrap">
          <div _ngcontent-ng-c453458495="" className="nav_dash_wrpLeft">
            <div _ngcontent-ng-c453458495="">
              <a
                _ngcontent-ng-c453458495=""
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="dashIconFold"
                style={{
                  color: "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {isSidebarOpen ? (
                  <i class="fa-solid fa-xmark" />
                ) : (
                  <i class="fa-solid fa-bars" />
                )}
              </a>
            </div>
          </div>
          <div _ngcontent-ng-c453458495="" className="nav_dash_wrpRight">
            <div
              _ngcontent-ng-c453458495=""
              className="nav_right_notification userDropDown dropdown"
            >
              <a
                _ngcontent-ng-c453458495=""
                href="javascript:void(0);"
                data-bs-toggle="dropdown"
                className="rightMenuBtn langDropBtn"
              >
                {userData?.username}
                <img
                  style={{ marginLeft: "10px" }}
                  _ngcontent-ng-c453458495=""
                  alt=""
                  src="https://ik.imagekit.io/bmaxmbpyx/https://admin.jmd15.com/assets/img/user.png"
                />
                <div _ngcontent-ng-c453458495="" className="user_right_bal">
                  <span _ngcontent-ng-c453458495="" className="User_balance">
                    {" "}
                    Balance:{" "}
                    {//inr format
                    String(userData?.balance).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}{" "}
                  </span>
                  <span _ngcontent-ng-c453458495="" className="user_name">
                    <span _ngcontent-ng-c453458495="">
                      {userData?.username}
                    </span>
                    <svg _ngcontent-ng-c453458495="">
                      <use
                        _ngcontent-ng-c453458495=""
                        xlinkHref="#downarrow"
                      />
                    </svg>
                  </span>
                </div>
              </a>
              <div
                _ngcontent-ng-c453458495=""
                className="dropdown-menu dropdown-menu-end"
              >
                <a _ngcontent-ng-c453458495="" href="/changePassword">
                  <svg _ngcontent-ng-c453458495="">
                    <use _ngcontent-ng-c453458495="" xlinkHref="#padlock" />
                  </svg>{" "}
                  Change Password{" "}
                </a>
                <a _ngcontent-ng-c453458495="" onClick={() => logOut()}>
                  <svg _ngcontent-ng-c453458495="">
                    <use _ngcontent-ng-c453458495="" xlinkHref="#logout" />
                  </svg>{" "}
                  Log Out{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c453458495=""
          id="SearchModal"
          tabIndex={-1}
          aria-labelledby="SearchModalLabel"
          aria-hidden="true"
          className="sitemoadal modal fade"
        >
          <div _ngcontent-ng-c453458495="" className="modal-dialog modal-xl">
            <div _ngcontent-ng-c453458495="" className="modal-content">
              <div _ngcontent-ng-c453458495="" className="modal-header">
                <h5
                  _ngcontent-ng-c453458495=""
                  id="SearchModalLabel"
                  className="modal-title"
                >
                  User Details
                </h5>
                <button
                  _ngcontent-ng-c453458495=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c453458495="" className="modal-body">
                <div _ngcontent-ng-c453458495="" className="row g-3">
                  <div _ngcontent-ng-c453458495="" className="col-lg-6">
                    <div _ngcontent-ng-c453458495="" className="card-title">
                      {" "}
                      Game Lock{" "}
                    </div>
                    <div _ngcontent-ng-c453458495="" className="custom_table">
                      <div
                        _ngcontent-ng-c453458495=""
                        className="table-responsive"
                      >
                        <div
                          _ngcontent-ng-c453458495=""
                          className="text-center"
                        >
                          <p _ngcontent-ng-c453458495="">No records</p>
                        </div>
                        <table _ngcontent-ng-c453458495="" className="table">
                          <thead _ngcontent-ng-c453458495="">
                            <tr _ngcontent-ng-c453458495="">
                              <th _ngcontent-ng-c453458495="">EventName</th>
                              <th _ngcontent-ng-c453458495="">UserName</th>
                              <th _ngcontent-ng-c453458495="">UserActive</th>
                              <th _ngcontent-ng-c453458495="">BetActive</th>
                              <th _ngcontent-ng-c453458495="">FancyActive</th>
                            </tr>
                          </thead>
                          {/**/}
                        </table>
                      </div>
                    </div>
                  </div>
                  {/**/}
                  <div _ngcontent-ng-c453458495="" className="col-lg-6">
                    <div _ngcontent-ng-c453458495="" className="card-title">
                      {" "}
                      User Lock{" "}
                    </div>
                    <div _ngcontent-ng-c453458495="" className="custom_table">
                      <div
                        _ngcontent-ng-c453458495=""
                        className="table-responsive"
                      >
                        <div
                          _ngcontent-ng-c453458495=""
                          className="text-center"
                        >
                          <p _ngcontent-ng-c453458495="">No records</p>
                        </div>
                        <table _ngcontent-ng-c453458495="" className="table">
                          <thead _ngcontent-ng-c453458495="">
                            <tr _ngcontent-ng-c453458495="">
                              <th _ngcontent-ng-c453458495="">UserName</th>
                              <th _ngcontent-ng-c453458495="">AccountType</th>
                              <th _ngcontent-ng-c453458495="">UserActive</th>
                              <th _ngcontent-ng-c453458495="">BetActive</th>
                            </tr>
                          </thead>
                          {/**/}
                        </table>
                      </div>
                    </div>
                  </div>
                  {/**/}
                  <div _ngcontent-ng-c453458495="" className="col-lg-12">
                    <div _ngcontent-ng-c453458495="" className="card-title">
                      {" "}
                      Game Book{" "}
                    </div>
                    <div _ngcontent-ng-c453458495="" className="custom_table">
                      <div
                        _ngcontent-ng-c453458495=""
                        className="table-responsive"
                      >
                        <div
                          _ngcontent-ng-c453458495=""
                          className="text-center"
                        >
                          <p _ngcontent-ng-c453458495="">No records</p>
                        </div>
                        <table _ngcontent-ng-c453458495="" className="table">
                          <thead _ngcontent-ng-c453458495="">
                            <tr _ngcontent-ng-c453458495="">
                              <th _ngcontent-ng-c453458495="">Gametype</th>
                              <th _ngcontent-ng-c453458495="">EventName</th>
                              <th _ngcontent-ng-c453458495="">BookA</th>
                              <th _ngcontent-ng-c453458495="">NationA</th>
                              <th _ngcontent-ng-c453458495="">BookB</th>
                              <th _ngcontent-ng-c453458495="">NationB</th>
                              <th _ngcontent-ng-c453458495="">BookC</th>
                              <th _ngcontent-ng-c453458495="">NationC</th>
                              <th _ngcontent-ng-c453458495="">NoOrBet</th>
                            </tr>
                          </thead>
                          {/**/}
                        </table>
                      </div>
                    </div>
                  </div>
                  {/**/}
                  <div _ngcontent-ng-c453458495="" className="col-lg-6">
                    <div _ngcontent-ng-c453458495="" className="card-title">
                      {" "}
                      User Details{" "}
                    </div>
                    <div _ngcontent-ng-c453458495="" className="custom_table">
                      <div
                        _ngcontent-ng-c453458495=""
                        className="table-responsive"
                      >
                        <table _ngcontent-ng-c453458495="" className="table">
                          <thead _ngcontent-ng-c453458495="">
                            <tr _ngcontent-ng-c453458495="">
                              <th _ngcontent-ng-c453458495="">UserName</th>
                              <th _ngcontent-ng-c453458495="">Exposer</th>
                              <th _ngcontent-ng-c453458495="">Creditref</th>
                              <th _ngcontent-ng-c453458495="">ExpoLimits</th>
                              <th _ngcontent-ng-c453458495="">General</th>
                            </tr>
                          </thead>
                          <tbody _ngcontent-ng-c453458495="">
                            <tr _ngcontent-ng-c453458495="">
                              <td _ngcontent-ng-c453458495="" />
                              <td _ngcontent-ng-c453458495="" />
                              <td _ngcontent-ng-c453458495="" />
                              <td _ngcontent-ng-c453458495="" />
                              <td _ngcontent-ng-c453458495="" />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-ng-c453458495="" className="col-lg-6">
                    <div _ngcontent-ng-c453458495="" className="card-title">
                      {" "}
                      Fancy Book{" "}
                    </div>
                    <div _ngcontent-ng-c453458495="" className="custom_table">
                      <div
                        _ngcontent-ng-c453458495=""
                        className="table-responsive"
                      >
                        <div
                          _ngcontent-ng-c453458495=""
                          className="text-center"
                        >
                          <p _ngcontent-ng-c453458495="">No records</p>
                        </div>
                        <table _ngcontent-ng-c453458495="" className="table">
                          <thead _ngcontent-ng-c453458495="">
                            <tr _ngcontent-ng-c453458495="">
                              <th _ngcontent-ng-c453458495="">EventName</th>
                              <th _ngcontent-ng-c453458495="">Book</th>
                            </tr>
                          </thead>
                          {/**/}
                        </table>
                      </div>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-header>
    </div>
  );
}

export default Header;
