import React, { useEffect, useState } from "react";
import { getClientAPI } from "../service/UserService";
import { getUsersWithUserTypeAPI } from "../service/UserService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkActiveDeactiveAPI,
  getCurrentBetsAPI,
  updateCommissionAPI,
  updateUserStatusAPI,
  currentBetsAPI,
  unSettledBetsAPI,
} from "../service/AuthService";
import toast from "react-hot-toast";
import store from "../redux/store";
import { PERMISSION_MAP } from "../utils/constants";
import { Spinner } from "@chakra-ui/react";
import moment from "moment";
import { useQuery } from "react-query";
import { Modal } from "react-bootstrap";
export default function MyClients() {
  const [search, setSearch] = useState("");
  const [userTypeName, setUserType] = useState("");
  const [clientData, setClientData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userTypeList, setGetUsersWithUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(50);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [userId, setGetUserId] = useState("");
  const userData = store.getState().account.userData;
  const location = useLocation();
  const { userType, userName } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(location?.state?.userName || "");
  const [checkedItems, setCheckedItems] = useState([]);
  const [isActive, setIsActive] = useState(null);
  const [exposureTableModal, setExposureTableModal] = useState();
  const [userUnsettledBets, setUserUnsettledBets] = useState([]);

  const [showDetails, setShowDetails] = useState(
    Array(clientData?.childrenUserResponseDTOS?.length).fill(false)
  );
  const getUnsettledBetByUsername = async (username) => {
    const {
      response: { content },
    } = await unSettledBetsAPI({
      pageSize: 1000,
      pageNumber: 0,

      betStatus: "MATCHED",
    });
    setUserUnsettledBets(
      content?.filter((item) => item.userName == username)
    );
  };

  const toggleDetails = (index) => {
    const newShowDetails = [...showDetails];
    newShowDetails[index] = !newShowDetails[index];
    setShowDetails(newShowDetails);
  };

  const [isMobileUI, setIsMobileUI] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileUI(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUsersWithUserType = async () => {
    let userTypeGet;
    if (userType?.toUpperCase() === "SUB_ADMIN") {
      userTypeGet = "ADMIN";
    } else if (userType?.toUpperCase() === "MASTER") {
      userTypeGet = "SUB_ADMIN";
    } else if (userType?.toUpperCase() === "SUPER_AGENT") {
      userTypeGet = "MASTER";
    } else if (userType?.toUpperCase() === "AGENT") {
      userTypeGet = "SUPER_AGENT";
    } else if (userType?.toUpperCase() === "CLIENT") {
      userTypeGet = "AGENT";
    }
    setUserType(userTypeGet);
    const { response } = await getUsersWithUserTypeAPI(userTypeGet);
    let response_ = response;
    if (response_) {
      setGetUsersWithUsers(response_);
    }
  };
  const handleAllStatus = async (status) => {
    try {
      setIsLoading(true);
      const { response, code } = await bulkActiveDeactiveAPI(
        status,
        checkedItems.length == 0 ? userType?.toLocaleUpperCase() : null,
        checkedItems
      );
      if (code == 200) {
        getClient();
        window.location.reload();
        toast.success(`All ${userType} status changed`);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const handleActiveInactive = async (userData) => {
    const { response, code } = await updateUserStatusAPI(
      userData?.uid,
      !userData?.isActive,
      userData?.userType != "CLIENT" ? true : !userData?.isBetLocked
    );
    if (code == 200) {
      getClient();
      window.location.reload();
      toast.success(`User status changed`);
    } else {
      toast.error(response);
    }
  };
  const getClient = async () => {
    const { response } = await getClientAPI(
      search,
      currentPage,
      size,
      id,
      userType?.toUpperCase(),
      location?.state?.blocked,
      isActive != null ? isActive : null,
      userName
    );
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS = response_.childrenUserResponseDTOSPage.content.sort(
        (a, b) => a.uid - b.uid
      );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }
    setClientData(response_);
  };

  const navigate = useNavigate();
  useEffect(() => {
    getClient();
    getUsersWithUserType();
  }, [
    location.state,
    location.search,
    location.pathname,
    size,
    currentPage,
    isActive,
    search,
  ]);

  const handleSelectChangeUser = (e) => {
    const userID = e.target.value;
    setGetUserId(userID);
  };
  useEffect(() => {
    setShowDetails(
      Array(clientData?.childrenUserResponseDTOS?.length).fill(false)
    );
  }, [userType]);
  const shareDetails = (client) => {
    let url = `https://api.whatsapp.com/send?text=${client?.username}\n ${
      client?.password
    }\n ${
      client?.userType?.toLowerCase() == "client"
        ? `\n${process.env?.REACT_APP_NAME}.com`
        : `\n${
            process.env?.REACT_APP_NAME
          }.com/admin`
    }`;
    window.open(url, "_blank");
  };
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-admin-details _nghost-ng-c3771564327="">
        <div _ngcontent-ng-c3771564327="" className="page_header">
          <h1 _ngcontent-ng-c3771564327="" className="page_heading">
            {userType} Details
          </h1>
          <div _ngcontent-ng-c3771564327="" className="breadcrumb_block">
            <nav _ngcontent-ng-c3771564327="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c3771564327="" className="breadcrumb">
                <li _ngcontent-ng-c3771564327="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3771564327="" href="#/dashboard">
                    Home
                  </a>
                </li>

                <li
                  _ngcontent-ng-c3771564327=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {userType?.toUpperCase()} Details
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c3771564327=""
          className="Super_master_details_section"
        >
          <div _ngcontent-ng-c3771564327="" className="top_baroption">
            <div _ngcontent-ng-c3771564327="" className="btn_left">
              <button
                _ngcontent-ng-c3771564327=""
                type="button"
                className="btn btn_yellow"
                tabIndex={0}
                onClick={() => {
                  navigate(`/create/new/${userType}`);
                }}
              >
                Create
              </button>
              <button
                _ngcontent-ng-c3771564327=""
                type="button"
                onClick={() => {
                  navigate(`/update_limit/${userType}`);
                }}
                className="btn btn_secondery"
                tabIndex={0}
              >
                Update Limit
              </button>
            </div>
            {/**/}
            <div _ngcontent-ng-c3771564327="" className="right_search_box">
              <div _ngcontent-ng-c3771564327="" className="search_bar_right">
                <input
                  _ngcontent-ng-c3771564327=""
                  type="text"
                  id="name"
                  onChange={(e) => setSearch(e.target.value)}
                  name="name"
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
                <button
                  _ngcontent-ng-c3771564327=""
                  type="submit"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div _ngcontent-ng-c3771564327="" className="custom_table">
            {/**/}
            <div _ngcontent-ng-c3771564327="" className="table-responsive">
              <table _ngcontent-ng-c3771564327="" className="table">
                <thead _ngcontent-ng-c3771564327="">
                  <tr _ngcontent-ng-c3771564327="">
                    <th _ngcontent-ng-c3771564327="">SNo</th>
                    <th _ngcontent-ng-c3771564327="">Name</th>
                    <th _ngcontent-ng-c3771564327="">Parent Name</th>
                    <th _ngcontent-ng-c3771564327="">D.O.J.</th>
                    {userType?.toUpperCase() == "CLIENT" && (
                      <th _ngcontent-ng-c3771564327="">Engaged Limit</th>
                    )}
                    <th _ngcontent-ng-c3771564327="">Sport Share%</th>
                    <th _ngcontent-ng-c3771564327="">Type</th>
                    <th _ngcontent-ng-c3771564327="">Mat</th>
                    <th _ngcontent-ng-c3771564327="">Ses </th>
                    <th _ngcontent-ng-c3771564327="">Limit</th>
                    <th _ngcontent-ng-c3771564327="">User Lock</th>

                    <th _ngcontent-ng-c3771564327="" />
                    <th _ngcontent-ng-c3771564327="">Setting</th>
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c3771564327="">
                  {clientData?.childrenUserResponseDTOS?.map(
                    (client, index) => (
                      <tr _ngcontent-ng-c3771564327="">
                        <td _ngcontent-ng-c3771564327="">{index + 1}</td>
                        {/**/}
                        <td
                          onClick={() => {
                            navigate(
                              `/${client?.userType}/children/${
                                client?.username
                              }`
                            );
                          }}
                          _ngcontent-ng-c3771564327=""
                        >
                          {" "}
                          {client?.name}
                          <br _ngcontent-ng-c3771564327="" /> (
                          {client?.username})
                        </td>
                        {/**/}
                        <td _ngcontent-ng-c3771564327="">
                          {" "}
                          {client?.parentUserName}{" "}
                          <br _ngcontent-ng-c3771564327="" /> (
                          {client?.parentUserId})
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          <span
                            _ngcontent-ng-c3771564327=""
                            style={{ width: 63, display: "inline-block" }}
                          >
                            {moment(client?.createdAt).format(
                              "DD-MM-YYYY hh:mm A"
                            )}
                          </span>
                        </td>
                        {userType?.toUpperCase() == "CLIENT" && (
                          <td
                            style={{
                              color: "blue",
                              fontWeight: "bold",
                            }}
                            _ngcontent-ng-c3771564327=""
                          >
                            {" "}
                            {Math.abs(client?.liability)}
                          </td>
                        )}
                        <td _ngcontent-ng-c3771564327="">
                          {client.myPartnership}
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          {client?.matchCommission == 0 ||
                          client?.sessionCommission == null
                            ? "NOC"
                            : "BYB"}
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          {client?.matchCommission}
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          {client?.sessionCommission}
                        </td>
                        <td
                          _ngcontent-ng-c3771564327=""
                          className="plus_value"
                        >
                          {parseFloat(
                            client.balance + client.liability
                          ).toFixed(2)}{" "}
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          <div
                            _ngcontent-ng-c3771564327=""
                            className="form-check form-switch text-success"
                          >
                            <input
                              _ngcontent-ng-c3771564327=""
                              type="checkbox"
                              checked={client?.isActive}
                              role="switch"
                              onClick={() => handleActiveInactive(client)}
                              id="flexSwitchCheckDefault4"
                              className="form-check-input"
                            />
                          </div>
                        </td>

                        <td _ngcontent-ng-c3771564327="">
                          <button
                            _ngcontent-ng-c3771564327=""
                            type="button"
                            className="btn btn-primary"
                            tabIndex={0}
                            onClick={() => {
                              navigate(`/report/account_statement/${
                                client?.uid
                              }
                              `);
                            }}
                          >
                            {" "}
                            Reports{" "}
                          </button>
                        </td>
                        <td _ngcontent-ng-c3771564327="">
                          <div
                            _ngcontent-ng-c3771564327=""
                            className="dropdown action_drop"
                          >
                            <button
                              _ngcontent-ng-c3771564327=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              className="btn btn-primary dropdown-toggle plus_value"
                            >
                              {" "}
                              Settings{" "}
                            </button>
                            <ul
                              _ngcontent-ng-c3771564327=""
                              className="dropdown-menu"
                            >
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  onClick={() => {
                                    navigate(
                                      `/edit/${client.uid}/${userType}`,
                                      {
                                        state: {
                                          client,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  Edit
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  onClick={() =>
                                    navigate(`/update/limit/${userType}`, {
                                      state: {
                                        client,
                                      },
                                    })
                                  }
                                >
                                  Client Limit
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  // data-bs-toggle="modal"
                                  data-bs-target="#detailaModal"
                                  className="dropdown-item"
                                  onClick={() => {
                                    shareDetails(client);
                                  }}
                                >
                                  Send Login Details
                                </a>
                              </li>
                              <li _ngcontent-ng-c3771564327="">
                                <a
                                  _ngcontent-ng-c3771564327=""
                                  className="dropdown-item"
                                  href={`/setting/change-password/${
                                    client?.uid
                                  }`}
                                >
                                  Change Password
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )
                  )}

                  {/**/}
                </tbody>
                {/**/}
              </table>
              <div _ngcontent-ng-c3771564327="" className="text-center">
                <span _ngcontent-ng-c3771564327="" colSpan={4}>
                  <pagination-controls
                    _ngcontent-ng-c3771564327=""
                    previouslabel="Previous"
                    nextlabel="Next"
                    screenreaderpaginationlabel="Pagination"
                    screenreaderpagelabel="page"
                    screenreadercurrentlabel="You're on page"
                  >
                    <pagination-template>
                      <nav role="navigation" aria-label="Pagination">
                        <ul className="ngx-pagination">
                          <li
                            onClick={() => {
                              if (currentPage > 0) {
                                setCurrentPage(currentPage - 1);
                              }
                            }}
                            className="pagination-previous disabled"
                          >
                            {/**/}
                            <span aria-disabled="true">
                              {" "}
                              Previous{" "}
                              <span className="show-for-sr">page</span>
                            </span>
                            {/**/}
                          </li>
                          {/**/}
                          <li className="small-screen">
                            {" "}
                            {currentPage + 1} / {totalPages}{" "}
                          </li>
                          <li className="current">
                            {/**/}
                            <span aria-live="polite">
                              <span className="show-for-sr">
                                You're on page{" "}
                              </span>
                              <span>{currentPage + 1}</span>
                            </span>
                            {/**/}
                            {/**/}
                          </li>

                          <li
                            onClick={() => {
                              if (currentPage < totalPages - 1) {
                                setCurrentPage(currentPage + 1);
                              }
                            }}
                            className="pagination-next"
                          >
                            <a tabIndex={0}>
                              {" "}
                              Next <span className="show-for-sr">page</span>
                            </a>
                            {/**/}
                            {/**/}
                          </li>
                          {/**/}
                        </ul>
                        {/**/}
                      </nav>
                    </pagination-template>
                  </pagination-controls>
                </span>
                {/**/}
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c3771564327=""
          id="detailaModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          className="sitemoadal modal fade"
        >
          <div
            _ngcontent-ng-c3771564327=""
            className="modal-dialog modal-dialog-centered"
          >
            <div _ngcontent-ng-c3771564327="" className="modal-content">
              <div _ngcontent-ng-c3771564327="" className="modal-header">
                <h5
                  _ngcontent-ng-c3771564327=""
                  id="exampleModalLabel"
                  className="modal-title"
                >
                  User Deatils
                </h5>
                <button
                  _ngcontent-ng-c3771564327=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c3771564327="" className="modal-body">
                <div _ngcontent-ng-c3771564327="" className="bet-slip-box">
                  <div _ngcontent-ng-c3771564327="" className="bet-slip">
                    <div _ngcontent-ng-c3771564327="" className="bet-nation">
                      <span _ngcontent-ng-c3771564327="">UserName : </span>
                    </div>
                    <div
                      _ngcontent-ng-c3771564327=""
                      className="match-result"
                    />
                  </div>
                  <div _ngcontent-ng-c3771564327="" className="bet-slip">
                    <div _ngcontent-ng-c3771564327="" className="bet-nation">
                      <span _ngcontent-ng-c3771564327="">password :</span>
                    </div>
                    <div
                      _ngcontent-ng-c3771564327=""
                      className="match-result"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c3771564327=""
                    className="place-bet-btn mt-4 text-align"
                  >
                    <button
                      _ngcontent-ng-c3771564327=""
                      className="btn btn-primary btn-block"
                    >
                      <span _ngcontent-ng-c3771564327="">
                        Share on WhatsApp
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c3771564327=""
          id="ViewMoreModal"
          tabIndex={-1}
          aria-labelledby="ViewMoreModalLabel"
          aria-hidden="true"
          className="sitemoadal modal fade"
        >
          <div _ngcontent-ng-c3771564327="" className="modal-dialog">
            <div _ngcontent-ng-c3771564327="" className="modal-content">
              <div _ngcontent-ng-c3771564327="" className="modal-header">
                <h5 _ngcontent-ng-c3771564327="" className="modal-title">
                  Modal
                </h5>
                <button
                  _ngcontent-ng-c3771564327=""
                  type="button"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div
                _ngcontent-ng-c3771564327=""
                className="modal-body text-center"
              >
                <p _ngcontent-ng-c3771564327="" className="message_peragraph">
                  Do you want to Lock/Unlock this user?
                </p>
                <div
                  _ngcontent-ng-c3771564327=""
                  className="place-bet-btn mt-4 text-align"
                >
                  <button
                    _ngcontent-ng-c3771564327=""
                    data-bs-dismiss="modal"
                    className="btn btn-primary btn-danger btn-block me-2"
                  >
                    <span _ngcontent-ng-c3771564327="">Cancel</span>
                  </button>
                  <button
                    _ngcontent-ng-c3771564327=""
                    className="btn btn-primary btn-block"
                  >
                    <span _ngcontent-ng-c3771564327="">Ok</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c3771564327=""
          id="betLockModal"
          tabIndex={-1}
          aria-labelledby="betLockModalLabel"
          aria-hidden="true"
          className="sitemoadal modal fade"
        >
          <div _ngcontent-ng-c3771564327="" className="modal-dialog">
            <div _ngcontent-ng-c3771564327="" className="modal-content">
              <div _ngcontent-ng-c3771564327="" className="modal-header">
                <h5 _ngcontent-ng-c3771564327="" className="modal-title">
                  Modal
                </h5>
                <button
                  _ngcontent-ng-c3771564327=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div
                _ngcontent-ng-c3771564327=""
                className="modal-body text-center"
              >
                <p _ngcontent-ng-c3771564327="" className="message_peragraph">
                  Do you want to Lock/Unlock this Bet?
                </p>
                <div
                  _ngcontent-ng-c3771564327=""
                  className="place-bet-btn mt-4 text-align"
                >
                  <button
                    _ngcontent-ng-c3771564327=""
                    data-bs-dismiss="modal"
                    className="btn btn-primary btn-danger btn-block me-2"
                  >
                    <span _ngcontent-ng-c3771564327="">Cancel</span>
                  </button>
                  <button
                    _ngcontent-ng-c3771564327=""
                    className="btn btn-primary btn-block"
                  >
                    <span _ngcontent-ng-c3771564327="">Ok</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-admin-details>
      {/**/}
    </div>
  );
}
