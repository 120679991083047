import React, { useEffect, useState } from "react";
import { dashboardBannerAPI, getAllMatchesAPI } from "../service/AuthService";
import { useDispatch } from "react-redux";
import store from "../redux/store";
import { Link, useNavigate } from "react-router-dom";
import { PERMISSION_MAP } from "../utils/constants";
import {
  getAllCricketMatchListAPI,
  getAllMatchesOddsCricketAPI,
} from "../service/cricket";

export default function Dashboard() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [homeImages, setHomeImages] = React.useState([]);
  const userData = store.getState().account.userData;
  const getBannerImages = async () => {
    var { response, code } = await dashboardBannerAPI();
    if (code === 200) {
      setHomeImages(response);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getBannerImages();
  }, []);
  const [matches, setMatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTree, setSelectedTree] = useState(null);
  const navigate = useNavigate();
  const getAllMatches = async () => {
    const { response } = await getAllMatchesOddsCricketAPI();
    setMatches(response);
  };

  useEffect(() => {
    getAllMatches();
  }, [currentPage]);
  const linkTree = {
    admin: [
      {
        name: "Admin",
        link: "/admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "ADMIN",
      },
      {
        name: "Sub Admin",
        link: "/sub_admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUB_ADMIN",
      },
      {
        name: "Master",
        link: "/master",
        icon: "fa fa-user",
        isPermission: true,
        enum: "MASTER",
      },
      {
        name: "Super Agent",
        link: "/super_agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUPER_AGENT",
      },
      {
        name: "Agent",
        link: "/agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "AGENT",
      },
      {
        name: "Client",
        link: "/client",
        icon: "fa fa-user",
        isPermission: true,
        enum: "CLIENT",
      },
    ],

    ledger: [
      {
        name: "Admin Ledger",
        link: "/ledger/admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "ADMIN",
      },
      {
        name: "Sub Admin Ledger",
        link: "/ledger/sub_admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUB_ADMIN",
      },
      {
        name: "Master Ledger",
        link: "/ledger/master",
        icon: "fa fa-user",
        isPermission: true,
        enum: "MASTER",
      },
      {
        name: "Super Agent Ledger",
        link: "/ledger/super_agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUPER_AGENT",
      },
      {
        name: "Agent Ledger",
        link: "/ledger/agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "AGENT",
      },
      {
        name: "Client Ledger",
        link: "/ledger/client",
        icon: "fa fa-user",
        isPermission: true,
        enum: "CLIENT",
      },
    ],
    cash: [
      {
        name: "Debit/Credit Entry (A)",
        link: "/ct/admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "ADMIN",
      },
      {
        name: "Debit/Credit Entry (SA)",
        link: "/ct/sub_admin",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUB_ADMIN",
      },
      {
        name: "Debit/Credit Entry (M)",
        link: "/ct/master",
        icon: "fa fa-user",
        isPermission: true,
        enum: "MASTER",
      },
      {
        name: "Debit/Credit Entry (SA)",
        link: "/ct/super_agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "SUPER_AGENT",
      },
      {
        name: "Debit/Credit Entry (A)",
        link: "/ct/agent",
        icon: "fa fa-user",
        isPermission: true,
        enum: "AGENT",
      },
      {
        name: "Debit/Credit Entry (C)",
        link: "/ct/client",
        icon: "fa fa-user",
        isPermission: true,
        enum: "CLIENT",
      },
    ],
  };

  const userPermissions = PERMISSION_MAP[userData?.userType?.toUpperCase()];
  const renderComponent = (link, backgroundColor, text, number) => (
    <div className="col-lg-6 col-12" key={link}>
      <a href={link} />
      <div className={`small-box ${backgroundColor}`}>
        <a href={link}>
          <div className="inner" style={{ padding: 12 }}>
            <h3>{number}</h3>
            <p>{text}</p>
          </div>
          <div className="icon">
            <i className="ion ion-bag" />
          </div>
        </a>
        <a href={link} className="small-box-footer">
          More Info <i className="fas fa-arrow-circle-right" />
        </a>
      </div>
    </div>
  );

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-dashboard _nghost-ng-c2891388947="">
        <div _ngcontent-ng-c2891388947="" className="page_header">
          <h1 _ngcontent-ng-c2891388947="" className="page_heading">
            Dashboard
          </h1>
          <div _ngcontent-ng-c2891388947="" className="breadcrumb_block">
            <nav _ngcontent-ng-c2891388947="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c2891388947="" className="breadcrumb">
                <li _ngcontent-ng-c2891388947="" className="breadcrumb-item">
                  <a
                    _ngcontent-ng-c2891388947=""
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c2891388947=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Dashboard
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div _ngcontent-ng-c2891388947="" className="px-md-0 px-2">
          <div _ngcontent-ng-c2891388947="" className="row g-3">
            {selectedTree ? (
              linkTree[selectedTree].map((item) => {
                if (item.isPermission) {
                  if (userPermissions.includes(item.enum)) {
                    return (
                      <div
                        _ngcontent-ng-c2891388947=""
                        onClick={() => navigate(item.link)}
                        className="col-6 col-sm-6 col-md-6 col-lg-4 col-xxl-3"
                      >
                        <div
                          _ngcontent-ng-c2891388947=""
                          className="dash_box"
                        >
                          <a _ngcontent-ng-c2891388947="">
                            <div
                              _ngcontent-ng-c2891388947=""
                              className="dash_icon_box light_yellow"
                            >
                              <i className={item?.icon} />
                            </div>
                            <h2 _ngcontent-ng-c2891388947="">{item?.name}</h2>
                          </a>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              })
            ) : (
              <>
                <div
                  _ngcontent-ng-c2891388947=""
                  className="col-6 col-sm-6 col-md-6 col-lg-4 col-xxl-3"
                >
                  <div _ngcontent-ng-c2891388947="" className="dash_box">
                    <a
                      _ngcontent-ng-c2891388947=""
                      onClick={() => setSelectedTree("admin")}
                    >
                      <div
                        _ngcontent-ng-c2891388947=""
                        className="dash_icon_box light_yellow"
                      >
                        <img src="https://cdn-icons-png.flaticon.com/512/560/560199.png" />
                      </div>
                      <h2 _ngcontent-ng-c2891388947="">
                        {userData?.userType} Details
                      </h2>
                    </a>
                  </div>
                </div>

                <div
                  _ngcontent-ng-c2891388947=""
                  className="col-6 col-sm-6 col-md-6 col-lg-4 col-xxl-3"
                >
                  <div _ngcontent-ng-c2891388947="" className="dash_box">
                    <a _ngcontent-ng-c2891388947="" href="/game/inPlay">
                      <div
                        _ngcontent-ng-c2891388947=""
                        className="dash_icon_box light_blue"
                      >
                        <img src="https://cdn-icons-png.flaticon.com/512/1809/1809206.png" />
                      </div>
                      <h2 _ngcontent-ng-c2891388947="">Sport's Betting</h2>
                    </a>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2891388947=""
                  className="col-6 col-sm-6 col-md-6 col-lg-4 col-xxl-3"
                >
                  <div _ngcontent-ng-c2891388947="" className="dash_box">
                    <a
                      _ngcontent-ng-c2891388947=""
                      onClick={() => setSelectedTree("ledger")}
                    >
                      <div
                        _ngcontent-ng-c2891388947=""
                        className="dash_icon_box light_green"
                      >
                        <img src="https://cdn-icons-png.flaticon.com/512/5848/5848083.png" />
                      </div>
                      <h2 _ngcontent-ng-c2891388947="">Ledger</h2>
                    </a>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2891388947=""
                  className="col-6 col-sm-6 col-md-6 col-lg-4 col-xxl-3"
                >
                  <div _ngcontent-ng-c2891388947="" className="dash_box">
                    <a
                      _ngcontent-ng-c2891388947=""
                      onClick={() => setSelectedTree("cash")}
                    >
                      <div
                        _ngcontent-ng-c2891388947=""
                        className="dash_icon_box light_orange"
                      >
                        <img src="https://cdn-icons-png.flaticon.com/512/3029/3029373.png" />
                      </div>
                      <h2 _ngcontent-ng-c2891388947="">Cash Transaction</h2>
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
          <div _ngcontent-ng-c2891388947="" className="Betting_tbl">
            <div _ngcontent-ng-c2891388947="" className="row g-3">
              {matches
                ?.filter((match) => match?.inPlay)
                ?.map((item) => (
                  <div
                    _ngcontent-ng-c2891388947=""
                    onClick={() =>
                      navigate(`/game/matchPosition/${item?.cricketId}`)
                    }
                    className="col-sm-12 col-md-6 col-lg-6"
                  >
                    <div
                      _ngcontent-ng-c2891388947=""
                      className="bet_tbl_inner_block"
                    >
                      <div
                        _ngcontent-ng-c2891388947=""
                        className="bet_tbl_header"
                      >
                        <img
                          _ngcontent-ng-c2891388947=""
                          alt=""
                          className="imgWidth"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://admin.jmd15.com/assets/img/4.avif"
                        />
                        <h5 _ngcontent-ng-c2891388947="">Cricket</h5>
                        <div
                          _ngcontent-ng-c2891388947=""
                          className="inplay_dot"
                        >
                          {" "}
                          Inplay
                          <span _ngcontent-ng-c2891388947="" />
                        </div>
                      </div>

                      <div
                        _ngcontent-ng-c2891388947=""
                        className="bettbl_body"
                      >
                        <div
                          _ngcontent-ng-c2891388947=""
                          className="bet_tbl_row inplay-bg"
                        >
                          <div
                            _ngcontent-ng-c2891388947=""
                            className="bet_col_70"
                          >
                            <span
                              _ngcontent-ng-c2891388947=""
                              className="team_name"
                            >
                              {item?.eventName}
                            </span>
                          </div>
                          <div
                            _ngcontent-ng-c2891388947=""
                            className="bet_col_30"
                          >
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </app-dashboard>
    </div>
  );
}
