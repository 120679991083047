import React, { useEffect, useState } from "react";
import { getUserActivityAPI, getUsersByTypeAPI } from "../service/AuthService";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import ReactSelect from "react-select";

export default function LoginReport() {
  const [selectedUserName, setSelectedUserName] = useState(20);
  const [userType, setUserType] = useState("CLIENT");
  const [payload, setPayload] = useState({
    activityLogType: "LOGIN",
    from: moment()
      .subtract(7, "d")
      .toISOString(),
    to: moment().toISOString(),
    userName: "",
    pageSize: 100,
  });
  const [data, setData] = useState([]);
  const location = useLocation();
  const params = useParams();

  const [users, setUsers] = useState([]);
  const getUsersByType = async () => {
    const { response, code } = await getUsersByTypeAPI(
      userType?.toLocaleUpperCase()
    );
    if (code === 200) {
      setUsers(response?.users);
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
    }),
  };
  const getUserActivity = async () => {
    const { response, code } = await getUserActivityAPI(payload);
    if (code == 200) {
      setData(response?.content);
    }
  };
  const handleSubmit = async () => {
    if (payload?.activityLogType === "LOGIN") {
      getUserActivity();
    }
  };
  useEffect(() => {
    getUsersByType();
  }, [userType]);
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-report-details _nghost-ng-c219872124="">
        <div _ngcontent-ng-c219872124="" className="page_header">
          <h1 _ngcontent-ng-c219872124="" className="page_heading">
            Report Update Details
          </h1>
          <div _ngcontent-ng-c219872124="" className="breadcrumb_block">
            <nav _ngcontent-ng-c219872124="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c219872124="" className="breadcrumb">
                <li _ngcontent-ng-c219872124="" className="breadcrumb-item">
                  <a _ngcontent-ng-c219872124="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c219872124=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Report Update Details
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c219872124=""
          className="Super_master_details_section agentledger_block"
        >
          <form
            _ngcontent-ng-c219872124=""
            noValidate=""
            className="row g-lg-3 g-2 align-items-end ng-pristine ng-valid ng-touched"
          >
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div
                _ngcontent-ng-c219872124=""
                className="input_block position-relative"
              >
                <label _ngcontent-ng-c219872124="">User Type</label>
                <ReactSelect
                  options={[
                    {
                      label: "Client",
                      value: "CLIENT",
                    },
                    {
                      label: "Master",
                      value: "MASTER",
                    },
                    {
                      label: "Admin",
                      value: "ADMIN",
                    },
                    {
                      label: "Sub Admin",
                      value: "SUB_ADMIN",
                    },
                    {
                      label: "Agent",
                      value: "AGENT",
                    },
                  ]}
                  onChange={(e) => {
                    setUserType(e.value);
                  }}
                />
                {/**/}
              </div>
            </div>
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div
                _ngcontent-ng-c219872124=""
                className="input_block position-relative"
              >
                <label _ngcontent-ng-c219872124="">User</label>
                <ReactSelect
                  options={users.map((user) => ({
                    label: user.userName,
                    value: user.userId,
                  }))}
                  onChange={(e) => {
                    setSelectedUserName(e.value);
                    setPayload({ ...payload, userName: e.label });
                  }}
                />
                {/**/}
              </div>
            </div>
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div _ngcontent-ng-c219872124="" className="input_block">
                <label _ngcontent-ng-c219872124="" htmlFor="type">
                  Report Type{" "}
                </label>
                <select
                  onChange={(e) => {
                    setPayload({
                      ...payload,
                      activityLogType: e.target.value,
                    });
                  }}
                  _ngcontent-ng-c219872124=""
                  formcontrolname="reportType"
                  className="form-control form-select ng-pristine ng-valid ng-touched"
                >
                  <option _ngcontent-ng-c219872124="" value="">
                    Select Type
                  </option>
                  <option _ngcontent-ng-c219872124="" value={"LOGIN"}>
                    Login
                  </option>
                  <option _ngcontent-ng-c219872124="" value={1}>
                    Limit
                  </option>
                  <option _ngcontent-ng-c219872124="" value={3}>
                    commission
                  </option>
                  <option _ngcontent-ng-c219872124="" value={4}>
                    status
                  </option>
                  <option _ngcontent-ng-c219872124="" value={5}>
                    bet status
                  </option>
                </select>
              </div>
            </div>
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div _ngcontent-ng-c219872124="" className="input_block">
                <label _ngcontent-ng-c219872124="" htmlFor="type">
                  Date From
                </label>
                <input
                  _ngcontent-ng-c219872124=""
                  type="date"
                  value={moment(payload.from).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      from: moment(e.target.value).toISOString(),
                    })
                  }
                  formcontrolname="FromDate"
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-4 col-lg-3"
            >
              <div _ngcontent-ng-c219872124="" className="input_block">
                <label _ngcontent-ng-c219872124="" htmlFor="type">
                  Date To
                </label>
                <input
                  _ngcontent-ng-c219872124=""
                  type="date"
                  value={moment(payload.to).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      to: moment(e.target.value).toISOString(),
                    })
                  }
                  formcontrolname="EndDate"
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c219872124=""
              className="col-6 col-md-6 col-lg-3"
            >
              <div _ngcontent-ng-c219872124="" className="input_block">
                <button
                  onClick={getUserActivity}
                  _ngcontent-ng-c219872124=""
                  type="button"
                  className="btn btn-primary"
                  disabled=""
                >
                  Show Reports
                </button>
              </div>
            </div>
          </form>
        </div>
        <div _ngcontent-ng-c219872124="" className="custom_table">
          <div _ngcontent-ng-c219872124="" className="table-responsive ">
            <table _ngcontent-ng-c219872124="" className="table">
              <thead _ngcontent-ng-c219872124="">
                <tr _ngcontent-ng-c219872124="">
                  {data?.length > 0 &&
                    Object.keys(data[0]).map((key) => (
                      <th _ngcontent-ng-c219872124="" scope="col">
                        {key?.toUpperCase()}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody _ngcontent-ng-c219872124="" className="bg-light">
                {/**/}
                {data?.length > 0 &&
                  data.map((item) => (
                    <tr _ngcontent-ng-c219872124="">
                      {Object.keys(item).map((key) =>
                        key === "createdAt" ? (
                          <td _ngcontent-ng-c219872124="">
                            {moment(item[key] + "Z").format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </td>
                        ) : (
                          <td _ngcontent-ng-c219872124="">{item[key]}</td>
                        )
                      )}
                    </tr>
                  ))}
                {/**/}
              </tbody>
            </table>
          </div>
        </div>
      </app-report-details>
      {/**/}
    </div>
  );
}
